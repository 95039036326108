<script>
import Success from '~/assets/icons/success-checkmark.svg?inline';
import Warning from '~/assets/icons/warning-mark.svg?inline';

export default {
  props: {
    available: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    Icon() {
      return this.available ? Success : Warning;
    },
  },
};
</script>

<template>
  <div class="applicant-availability">
    <component :is="Icon" class="applicant-availability__icon" />

    <span class="applicant-availability__description">
      {{
        available
          ? $t('pages.applicant.dashboard.available')
          : $t('pages.applicant.dashboard.unavailable')
      }}
    </span>

    <div class="applicant-availability__actions">
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
.applicant-availability {
  background-color: #fff;
  border-radius: 12px;
  padding: 3rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  width: 25rem;

  &__icon {
    width: 125px;
    height: 125px;
  }

  &__description {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
  &__actions {
  }
}
</style>
