<template>
  <t-body>
    <t-sidebar>
      <div class="intro">
        <div>
          <h4 class="intro__welcome">
            {{ $t('fields.welcome'), }}
          </h4>
          <h3 class="intro__name">
            {{ user.fullname }}
          </h3>
        </div>
      </div>

      <template v-if="!$fetchState.pending" #footer>
        <div v-if="!applicant.looking" class="sidebar-task">
          <div class="sidebar-task__information">
            <h6>{{ $t('pages.applicant.pending.heading') }}</h6>
            <span>{{ $t('pages.applicant.pending.subheading') }}</span>
          </div>
          <t-button-loading :loading="loading" @click.prevent="updateLookingState">
            {{ $t('global.actions.update') }}
          </t-button-loading>
        </div>
        <div v-else class="sidebar-task">
          <div class="sidebar-task__information">
            <h6>{{ $t('pages.applicant.found.heading') }}</h6>
            <span>{{ $t('pages.applicant.found.subheading') }}</span>
          </div>
          <t-button-loading :loading="loading" @click.prevent="resetLookingState">
            {{ $t('global.actions.update') }}
          </t-button-loading>
        </div>
      </template>
    </t-sidebar>
    <t-page>
      <h3 slot="header">
        {{ $t('global.headings.dashboard') }}
      </h3>

      <TLoading v-if="$fetchState.pending" />
      <t-grid v-else>
        <t-grid-row>
          <ApplicantAvailability :available="applicant.available">
            <t-button-loading
              v-if="applicant.looking"
              :loading="loading"
              @click.prevent="resetLookingState"
            >
              {{ $t('pages.applicant.actions.disable_search') }}
            </t-button-loading>
            <t-button-loading v-else :loading="loading" @click.prevent="updateLookingState">
              {{ $t('pages.applicant.actions.enable_search') }}
            </t-button-loading>
          </ApplicantAvailability>

          <t-grid-cell>
            <t-count-widget :label="$t('fields.invited_by')" icon="search" card>
              {{ applicant.organization.name }}
            </t-count-widget>
          </t-grid-cell>
        </t-grid-row>

        <t-grid-row>
          <t-grid-cell>
            <h3>{{ $t('global.headings.what_is_ar') }}</h3>
            <iframe
              style="border-radius: 12px"
              width="50%"
              height="400"
              src="https://www.youtube.com/embed/EwZqNX90L4c"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </t-grid-cell>
        </t-grid-row>
      </t-grid>
    </t-page>
  </t-body>
</template>

<script>
import Vue from 'vue';
import Applicant from '~/models/Applicant';
import TLoading from '~/components/TLoading';
import ApplicantAvailability from '~/components/ApplicantAvailability';

export default Vue.extend({
  components: {
    TLoading,
    ApplicantAvailability,
  },

  data: () => ({
    applicant: null,

    loading: false,
  }),

  async fetch() {
    this.applicant = await Applicant.include('organization').$find(this.$auth.me.id);
  },

  computed: {
    me() {
      return this.$auth.me;
    },

    user() {
      return this.me.user;
    },
  },

  methods: {
    async updateLookingState() {
      this.loading = true;
      try {
        const applicant = new Applicant({
          id: this.applicant.id,
          looking: true,
        });
        const response = await applicant.patch();
        this.applicant.looking = true;
        this.applicant.available = response.available;
      } catch (e) {
        this.$axios.handleError(e);
      } finally {
        this.loading = false;
      }
    },

    async resetLookingState() {
      this.loading = true;
      try {
        const applicant = new Applicant({
          id: this.applicant.id,
          looking: false,
        });
        const response = await applicant.patch();
        this.applicant.looking = false;
        this.applicant.available = response.available;
      } catch (e) {
        this.$axios.handleError(e);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.intro {
  margin-top: $s-8;
  margin-bottom: $s-16;

  .organization-logo {
    margin-bottom: $s-4;
  }

  &__welcome {
    font-weight: 300;
    font-size: $font-size-2xl;
  }

  &__name {
    font-size: $font-size-3xl;
  }
}

.section {
  &__title {
  }
}

.sidebar-task {
  border-top: 1px solid var(--outline-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
</style>
